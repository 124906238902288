.handleScrolling {
    height: 100vh;
    width: 100%;
    position: absolute;
    overflow-y: auto;
}

.title {
    color: whitesmoke;
    font-size: 46px;
    text-align: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin: 10px 0;
    margin-top: 30px;
}

.description {
    color: whitesmoke;
    font-size: 16px;
    text-align: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.container {
    height: fit-content;
    width: 100%;
    position: relative;
}

.stars,
.twinkling,
.clouds {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: block;
}

@keyframes move-twink-back {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -10000px 5000px;
    }
}

@keyframes move-clouds-back {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 10000px 0;
    }
}

.stars {
    background: #000 url(stars.png) repeat top center;
    z-index: -5;
}

.twinkling {
    background: transparent url(twinkle-effect.png) repeat top center;
    z-index: -4;
    animation: move-twink-back 200s linear infinite;
}

.clouds {
    background: transparent url(clouds.png) repeat top center;
    z-index: -2;
    opacity: .4;
    animation: move-clouds-back 200s linear infinite;
}