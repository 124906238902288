.ImagesContainer {
    display: flex;
    width: 100vmin;
    height: 100%;
    margin: auto;
    flex-direction: column;
    box-sizing: border-box;
    padding: 20px;
    position: relative;
}

.loading {
    background-color: whitesmoke;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin: 0px auto 20px auto;
    top: 0;
    position: relative;
    animation: loading-bounce 1.5s infinite;
}

@keyframes loading-bounce {
    0% {
        transform: translateX(0) scaleY(50%);
        -webkit-transform: translateX(0) scaleY(50%);
        -moz-transform: translateX(0) scaleY(50%);
        -ms-transform: translateX(0) scaleY(50%);
        -o-transform: translateX(0) scaleY(50%);
    }
    25% {
        transform: translateX(-500%) scaleY(100%);
        -webkit-transform: translateX(-500%) scaleY(100%);
        -moz-transform: translateX(-500%) scaleY(100%);
        -ms-transform: translateX(-500%) scaleY(100%);
        -o-transform: translateX(-500%) scaleY(100%);
    }
    50% {
        transform: translateX(0) scaleY(50%);
        -webkit-transform: translateX(0) scaleY(50%);
        -moz-transform: translateX(0) scaleY(50%);
        -ms-transform: translateX(0) scaleY(50%);
        -o-transform: translateX(0) scaleY(50%);
    }
    75% {
        transform: translateX(+500%) scaleY(100%);
        -webkit-transform: translateX(+500%) scaleY(100%);
        -moz-transform: translateX(+500%) scaleY(100%);
        -ms-transform: translateX(+500%) scaleY(100%);
        -o-transform: translateX(+500%) scaleY(100%);
    }
    100% {
        transform: translateX(0) scaleY(50%);
        -webkit-transform: translateX(0) scaleY(50%);
        -moz-transform: translateX(0) scaleY(50%);
        -ms-transform: translateX(0) scaleY(50%);
        -o-transform: translateX(0) scaleY(50%);
    }
}